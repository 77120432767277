<template>
  <!-- scan/scan/scan START -->
  <div class="scan-terminal">
    <!-- qr-code:icon START -->
    <div class="icon">
      <img width="64" :src="iconTerminal" />
    </div>
    <!-- qr-code:icon END -->

    <h1 v-html="$t('terminal.scannen')"></h1>

    <!-- qr-code:code START -->
    <div class="code">
      <img v-if="iconCode" :src="iconCode" />
        <span
          class="f-like-image-loading"
          v-if="!iconCode"
        ></span>
    </div>
    <!-- qr-code:code END -->

    <!-- pay START -->
    <div class="pay" v-if="false">
      <p v-html="$t('terminal.final')"></p>
      <router-link
        class="btn-default"
        :to="'/cart/purchase-thank-you'"
      >{{ $t('cart.paynow') }}</router-link>
    </div>
    <!-- pay END -->
  </div>
  <!-- scan/scan/scan END -->
</template>

<script>
import titleMixin from '@/mixins/titleMixin';

import ICONS from '../../../../utils/icons';

const {
  iTerminal,
} = ICONS;

export default {
  name: 'scan-scan-terminal',
  mixins: [titleMixin],
  data() {
    return {
      /**
       * Title (Page) - If "pageTitleFrom" exists - show simple Navigation...
       */
      pageTitleFromComponent: this.$t('cart.paynow'),

      /**
       * QR Code Icon
       */
      qrcode: '',

      /**
       * Terminal Icon
       */
      iconTerminal: localStorage && localStorage.getItem('theme') === 'light' ? iTerminal.defaultUrl : iTerminal.greenUrl,
    };
  },
  async created() {
    this.qrcode = await this.FETCH_QRCODE();
  },
  computed: {
    iconCode() {
      return `${this.qrcode}`;
    },
  },
  methods: {
    async FETCH_QRCODE() {
      await this.$store.dispatch('setQrcode');
      return this.$store.getters.qrcode;
    },
  },
};
</script>
